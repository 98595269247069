export const RouteUtils = {
    LOGIN: "/login",
    SIGNUP: "/signup",
    DASHBOARD: "/dashboard",
    PROFIL: "/profil",
    SEARCH: "/search",
    PROJECT: "/projects",
    PERSONNELS: "/personnels",
    FORGOT_PASSWORD: "/mot-de-passe-oublie",
    MISSION_BROWSER: "/mission/browse",
};
