import React from "react";
import { Pagination, Stack } from "@mui/material";

interface PaginationProps {
    totalItems: number;
    itemsPerPage: number;
    currentPage: number;
    onPageChange: (page: number) => void;
}

const CustomPagination: React.FC<PaginationProps> = ({
    totalItems,
    itemsPerPage,
    currentPage,
    onPageChange,
}) => {
    const pageCount =
        itemsPerPage > 0 ? Math.ceil(totalItems / itemsPerPage) : 1;

    return (
        <Stack
            width="100%"
            spacing={2}
            marginTop="1rem"
            marginBottom="1rem"
            alignItems="center"
        >
            <Pagination
                count={pageCount}
                page={currentPage}
                onChange={(event, page) => onPageChange(page)}
                color="primary"
            />
        </Stack>
    );
};

export default CustomPagination;
