import { Input, Stack, Typography } from "@mui/material";
import styled from "@emotion/styled";
import { useState } from "react";
import { useFetchMissions } from "./hooks/useFetchMissions";
import { UserStructureResponse } from "../structures/model/StructureModel";
import { UserProfessionnelResponse } from "../professionnels/model/ProfessionnelModel";
import { useAppSelector } from "../common/redux/reduxHooks";
import { RootState } from "../common/redux/store";
import SortButton from "./components/SortButton";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import MissionProfessionnel from "./components/MissionProfessionnel";
import CustomPagination from "./components/Pagination";
import { ProfessionnelDashboard } from "./components/ProfessionnelDashboard";
import MissionStructure from "./components/MissionStructure";
import { RouteApp } from "../common/model/RouteApp";
import { StructureDashboard } from "./components/StructureDashboard";

export const MissionBrowsePage = () => {
    const [searchTerm, setSearchTerm] = useState("");
    const [sortOrder, setSortOrder] = useState("desc");
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage] = useState(10);

    const userStructure: UserStructureResponse = useAppSelector(
        (state: RootState) => state.user.data
    );
    const userProfessionnel: UserProfessionnelResponse = useAppSelector(
        (state: RootState) => state.user.proData
    );

    const role = userStructure?.societe
        ? "ROLE_STRUCTURE"
        : "ROLE_PROFESSIONNEL";

    const { missions, totalItems } = useFetchMissions({
        page: currentPage,
        itemsPerPage,
        sortOrder,
        search: searchTerm,
        role,
    });

    const userName = userStructure?.societe
        ? userStructure.societe
        : `${userProfessionnel?.nom} ${userProfessionnel?.prenom}`;

    const navigate = useNavigate();
    const { t } = useTranslation();

    const handleClick = (item: any) => {
        const routePath = userStructure?.societe
            ? RouteApp.MISSION_DETAILS_STR
            : RouteApp.MISSION_DETAILS_PRO;

        navigate(routePath.replace(":id", item.id), { state: { item } });
    };

    const handlePageChange = (page: number) => {
        setCurrentPage(page);
    };

    return (
        <PageWrapper>
            <Section>
                <Title>
                    {t("missions.browse.title")}
                    {userName}
                </Title>
                {userProfessionnel.nom && <ProfessionnelDashboard />}
                {userStructure.societe && <StructureDashboard />}
                <InputsContainer>
                    <SearchInput
                        placeholder={
                            userProfessionnel?.nom
                                ? t("missions.search.label")
                                : t("missions.search.label.name")
                        }
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                    />
                    <SortButton
                        sortOrder={sortOrder}
                        setSortOrder={setSortOrder}
                    />
                </InputsContainer>
                {userProfessionnel?.nom && (
                    <MissionProfessionnel
                        filteredMissions={missions}
                        handleClick={handleClick}
                        sortOrder={sortOrder}
                    />
                )}
                {userStructure?.societe && (
                    <MissionStructure
                        filteredMissions={missions}
                        handleClick={handleClick}
                        sortOrder={sortOrder}
                    />
                )}
                <CustomPagination
                    totalItems={totalItems}
                    itemsPerPage={itemsPerPage}
                    currentPage={currentPage}
                    onPageChange={handlePageChange}
                />
            </Section>
        </PageWrapper>
    );
};

export default MissionBrowsePage;

const PageWrapper = styled("div")({
    display: "flex",
    flexDirection: "column",
    minHeight: "100dvh",
    marginBottom: "2rem",
});

const Section = styled(Stack)({
    display: "flex",
    width: "100%",
    alignItems: "center",
    marginTop: "100px",
    gap: "40px",
    flexGrow: 1,
    flexDirection: "column",

    "@media(width >= 768px)": {
        alignItems: "start",
    },
});

const Title = styled(Typography)({
    fontFamily: "Montserrat",
    fontSize: "16px",
    textAlign: "center",
    width: "100%",

    "@media(width >= 768px)": {
        fontSize: "18px",
    },
});

const InputsContainer = styled(Stack)({
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    gap: "20px",
    width: "100%",
    flexDirection: "column",

    "@media(width >= 768px)": {
        justifyContent: "space-around",
        flexDirection: "row",
    },
});

const SearchInput = styled(Input)({
    width: "80%",
    fontSize: "14px",

    "@media(width >= 768px)": {
        width: "40%",
    },

    "@media(width >= 990px)": {
        width: "30%",
    },
});
