import React, { useEffect, useMemo, useState } from "react";
import {
    Box,
    Typography,
    Stack,
    Collapse,
    IconButton,
    Button,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import AvTimerIcon from "@mui/icons-material/AvTimer";
import PaymentIcon from "@mui/icons-material/Payment";
import EditIcon from "@mui/icons-material/Edit";
import { Timesheet } from "./MissionStructCard";
import styled from "@emotion/styled";
import ApproveTimeSheet from "./ApproveTimeSheet";
import { useTranslation } from "react-i18next";
import { formatTimeHHMM, getApproved } from "../utils/utils";

import { calculateWorkingHours, useTotal } from "../hooks/useTotal";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../common/redux/store";
import {
    deleteTimeSheet,
    getMissionById,
    updateTimeSheet,
} from "../../common/redux/missionActions";
import { useParams } from "react-router-dom";
import { useValidation } from "./useValidation";
import EditTimesheet from "./EditTimesheet";

interface MultipleTimesheetProps {
    date: string;
    timesheet: Timesheet[];
    index: number;
    missionId: string;
}

const MultipleTimesheet: React.FC<MultipleTimesheetProps> = ({
    date,
    timesheet,
    index,
    missionId,
}) => {
    const [expanded, setExpanded] = useState(false);
    const [localTimesheets, setLocalTimesheets] = useState(timesheet);
    const [currentEdit, setCurrentEdit] = useState<string | null>(null);
    const [editModalVisible, setEditModalVisible] = useState(false);
    const { showSuccess, SnackbarComponent } = useValidation();

    const mission = useSelector(
        (state: RootState) => state.missions.selectedMission
    );

    const { id } = useParams<{ id: string }>();
    const { t } = useTranslation();
    const dispatch = useDispatch<AppDispatch>();

    useEffect(() => {
        if (mission?.timeSheets) {
            const filteredTimesheets = mission.timeSheets.filter(
                (ts: Timesheet) => {
                    const timesheetDate = new Date(
                        ts.startDate
                    ).toLocaleDateString("fr-FR");
                    return timesheetDate === date;
                }
            );
            setLocalTimesheets(filteredTimesheets);
        }
    }, [mission?.timeSheets, date]);

    const formattedDate = (() => {
        const parts = date.split("/");
        if (parts.length === 3) {
            const [day, month] = parts;
            return `${day}/${month}`;
        }
        return "Invalid Date";
    })();

    const totalBillableHours = useMemo(() => {
        return timesheet.reduce((sum, ts) => {
            const hours = calculateWorkingHours(
                ts.startDate,
                ts.endDate,
                false,
                ts.billable
            );
            return sum + (typeof hours === "number" ? hours : 0);
        }, 0);
    }, [timesheet]);

    const formattedTotalBillableTime = useMemo(() => {
        return formatTimeHHMM(totalBillableHours);
    }, [totalBillableHours]);

    const totalBillableAmount = useMemo(() => {
        return timesheet.reduce(
            (sum, ts) => sum + (ts.billable || ts.cost || 0),
            0
        );
    }, [timesheet]);

    const { processedTimesheets } = useTotal(mission);

    const calculateOverallStatus = () => {
        if (localTimesheets.some((ts) => ts.status === "rejected")) {
            return "rejected";
        }
        if (localTimesheets.some((ts) => ts.status === "pending")) {
            return "pending";
        }
        if (localTimesheets.every((ts) => ts.status === "approved")) {
            return "approved";
        }
        return "unknown";
    };

    const overallStatus = useMemo(calculateOverallStatus, [localTimesheets]);
    const overallStatusInfo = getApproved(t, overallStatus);

    const handleStatusUpdate = (timesheetId: string, newStatus: string) => {
        setLocalTimesheets((prev) =>
            prev.map((ts) =>
                ts.id === timesheetId ? { ...ts, status: newStatus } : ts
            )
        );
    };

    const handleEdit = (
        timesheetId: string | null,
        updatedTimesheet: Timesheet
    ) => {
        if (timesheetId) {
            const { startDate, endDate } = updatedTimesheet;
            if (startDate && endDate) {
                setLocalTimesheets((prev) =>
                    prev.map((ts) =>
                        ts.id === timesheetId
                            ? { ...ts, startDate, endDate }
                            : ts
                    )
                );
                dispatch(
                    updateTimeSheet({
                        id: timesheetId,
                        startDate,
                        endDate,
                    })
                ).then(() => {
                    showSuccess(t("mission.edit.timesheet"));
                    if (id) {
                        dispatch(
                            getMissionById({
                                id,
                                role: "ROLE_PROFESSIONNEL",
                            })
                        );
                    }
                });
            }
        }
        setEditModalVisible(false);
    };

    const handleEditTimesheet = (timesheetId: string) => {
        const timesheet = localTimesheets.find((ts) => ts.id === timesheetId);
        if (timesheet) {
            setCurrentEdit(timesheetId);
            setEditModalVisible(true);
        }
    };

    const handleDeleteTimesheet = (timesheetId: string) => {
        dispatch(deleteTimeSheet(timesheetId))
            .then(() => {
                setLocalTimesheets((prev) =>
                    prev.filter((ts) => ts.id !== timesheetId)
                );
                showSuccess(t("mission.deleted.timesheet"));
            })
            .finally(
                () =>
                    id &&
                    dispatch(getMissionById({ id, role: "ROLE_PROFESSIONNEL" }))
            );
    };

    const handleCloseEditModal = () => {
        setEditModalVisible(false);
        setCurrentEdit(null);
    };

    return (
        <Box
            display="flex"
            flexDirection="column"
            alignItems="space-between"
            sx={{
                boxShadow:
                    "rgba(9, 30, 66, 0.15) 0px 1px 1px, rgba(9, 30, 66, 0.1) 0px 0px 1px 1px",
            }}
        >
            <Container
                onClick={() => setExpanded(!expanded)}
                sx={{
                    backgroundColor: index % 2 === 0 ? "#f7f7f7" : "white",
                }}
            >
                <DateBox>{formattedDate}</DateBox>
                <Sheet>
                    <span className="icon">
                        <AvTimerIcon />
                    </span>
                    <span className="text">{t("mission.dashboard.time")}</span>
                    {formattedTotalBillableTime} hr
                </Sheet>
                <Sheet>
                    <span className="icon">
                        <PaymentIcon />
                    </span>
                    <span className="text">
                        {t("mission.dashboard.amount")}
                    </span>
                    {totalBillableAmount}€
                </Sheet>
                <Status>
                    <Typography
                        sx={{
                            backgroundColor: overallStatusInfo.backgroundColor,
                            display: "flex",
                            alignItems: "center",
                            color: "#FAF9F6",
                            justifyContent: "center",
                            borderRadius: "5px",
                            padding: "0.45rem",
                            fontSize: "9px",
                            width: "100%",

                            "@media(width >= 768px)": {
                                fontSize: "12px",
                            },
                        }}
                    >
                        {overallStatusInfo.label}
                    </Typography>
                </Status>
                <CollapseBtn onClick={() => setExpanded(!expanded)}>
                    {expanded ? (
                        <ExpandLessIcon fontSize="large" />
                    ) : (
                        <ExpandMoreIcon fontSize="large" />
                    )}
                </CollapseBtn>
            </Container>
            <Collapse
                in={expanded}
                timeout="auto"
                unmountOnExit
                sx={{
                    backgroundColor: index % 2 === 0 ? "#f7f7f7" : "white",
                }}
            >
                <Box
                    display="flex"
                    flexDirection="column"
                    alignItems="space-between"
                    justifyContent="center"
                    marginTop="0.5rem"
                    gap="0.5rem"
                >
                    {localTimesheets.map((item) => (
                        <Stack
                            key={item.id}
                            direction="row"
                            alignItems="center"
                            justifyContent="space-around"
                            sx={{
                                borderBottom: "1px solid #e0e0e0",
                            }}
                        >
                            <SheetDetails>{formattedDate}</SheetDetails>
                            <SheetDetails>
                                {new Date(item.startDate)
                                    .toLocaleTimeString()
                                    .slice(0, -3)}
                            </SheetDetails>
                            <SheetDetails>
                                {new Date(item.endDate)
                                    .toLocaleTimeString()
                                    .slice(0, -3)}
                            </SheetDetails>
                            <SheetDetails>
                                <SheetDetails>
                                    {calculateWorkingHours(
                                        item.startDate,
                                        item.endDate,
                                        true,
                                        item.billable,
                                        item.cost
                                    )}
                                </SheetDetails>
                            </SheetDetails>
                            <SheetDetails>
                                {item.billable || item.cost || 0} €
                            </SheetDetails>
                            <SheetDetails>
                                <Typography
                                    sx={{
                                        backgroundColor: getApproved(
                                            t,
                                            item.status
                                        ).backgroundColor,
                                        color: "#FAF9F6",
                                        borderRadius: "5px",
                                        padding: "0.5rem",
                                        textAlign: "center",
                                        fontSize: "7px",

                                        flex: "1",
                                        "@media(width >= 768px)": {
                                            fontSize: "12px",
                                        },
                                    }}
                                >
                                    {getApproved(t, item.status).label}
                                </Typography>
                            </SheetDetails>
                            <SheetDetails>
                                {mission.isProfessional ? (
                                    <EditButton
                                        onClick={() =>
                                            handleEditTimesheet(item.id)
                                        }
                                        disabled={item.status === "approved"}
                                    >
                                        <EditIcon fontSize="small" />
                                    </EditButton>
                                ) : (
                                    <ApproveTimeSheet
                                        timesheet={item}
                                        timesheetId={item.id}
                                        missionId={missionId}
                                        disabled={item.status === "approved"}
                                        onSuccess={(newStatus) =>
                                            handleStatusUpdate(
                                                item.id,
                                                newStatus || ""
                                            )
                                        }
                                    />
                                )}
                            </SheetDetails>
                        </Stack>
                    ))}
                    <EditTimesheet
                        isOpen={editModalVisible}
                        onClose={handleCloseEditModal}
                        timesheet={
                            currentEdit
                                ? processedTimesheets.find(
                                      (ts: { id: string }) =>
                                          ts.id === currentEdit
                                  ) || {}
                                : {}
                        }
                        handleSubmit={handleEdit}
                        timesheetId={currentEdit}
                        handleDeleteTimesheet={handleDeleteTimesheet}
                        missionStartDate={mission.startDate}
                        missionEndDate={mission.endDate}
                    />
                    {SnackbarComponent}
                </Box>
            </Collapse>
        </Box>
    );
};

export default MultipleTimesheet;

const Container = styled(Stack)({
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-around",
    alignItems: "center",

    ":hover": {
        cursor: "pointer",
    },

    "@media(width >= 768px)": {
        justifyContent: "space-around",
    },

    "@media(width >= 1240px)": {
        justifyContent: "center",
        gap: "1rem",
    },
});

const Sheet = styled(Typography)({
    fontSize: "11px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    gap: "0.5rem",
    width: "80px",

    ".icon": {
        display: "flex",
        "@media (min-width: 990px)": {
            display: "none",
        },
    },
    ".text": {
        display: "none",
        "@media (min-width: 990px)": {
            display: "inline",
        },
    },

    "@media(width >= 425px)": {
        width: "90px",
    },

    "@media(width >= 768px)": {
        width: "120px",
        fontSize: "14px",
    },

    "@media(width >= 1240px)": {
        flex: "1",
        justifyContent: "center",
        gap: "2rem",
        fontSize: "16px",
    },
});

const SheetDetails = styled(Typography)({
    fontSize: "11px",
    display: "flex",
    justifyContent: "center",

    flex: "0 1 100%",
    padding: "1px",
    "@media(width >= 768px)": {
        fontSize: "14px",
    },
});

const DateBox = styled(Typography)({
    fontSize: "11px",
    display: "flex",
    justifyContent: "center",
    width: "40px",

    "@media(width >= 768px)": {
        fontSize: "14px",
        width: "50px",
    },

    "@media(width >= 1240px)": {
        width: "120px",
    },

    "@media(width >= 1600px)": {
        width: "138px",
    },

    "@media(width >= 1900px)": {
        width: "160px",
    },
});

const CollapseBtn = styled(IconButton)({
    width: "30px",
    borderRadius: "1px",
    padding: 0,
    margin: 0,

    "@media(width >= 768px)": {
        width: "40px",
    },

    "@media(width >= 1240px)": {
        width: "100px",
    },

    "@media(width >= 1600px)": {
        width: "123px",
    },

    "@media(width >= 1900px)": {
        width: "142px",
    },
});

const EditButton = styled(Button)({
    margin: 0,
    width: "25px !important",
    minWidth: "25px !important",
    padding: "2px",
});

const Status = styled(Typography)({
    width: "50px",

    "@media(width >= 425px)": {
        width: "55px",
    },

    "@media(width >= 768px)": {
        width: "72px",
    },

    "@media(width >= 1240px)": {
        width: "120px",
    },

    "@media(width >= 1600px)": {
        width: "135px",
    },

    "@media(width >= 1900px)": {
        width: "160px",
    },
});
