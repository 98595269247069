import dayjs from "dayjs";

export const calculateMissionStatus = (
    startDate: string,
    endDate: string,
    t: (key: string) => string
) => {
    const now = dayjs();
    const start = dayjs(startDate);
    const end = dayjs(endDate);

    if (now.isBefore(start)) {
        return t("mission.status.upcoming");
    } else if (now.isAfter(end)) {
        return t("mission.status.past");
    } else if (now.isBetween(start, end, "day", "[]")) {
        return t("mission.status.progress");
    } else {
        return t("mission.status.past");
    }
};
