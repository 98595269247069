import { configureStore } from "@reduxjs/toolkit";
import authReducer from "../../login/redux/authSlice";
import signUpSlice from "../../structures/redux/signUpSlice";
import signUpSliceProfessionnel from "../../professionnels/redux/signUpSlice";
import userSlice from "./userSlice";
import projectSlice from "../../structures/redux/projectSlice";
import searchSlice from "../../search/redux/searchSlice";
import professionnelSlice from "../../professionnels/redux/professionnelSlice";
import { profilSlice } from "../../profil/redux/profilSlice";
import structureSlice from "../../structures/redux/structureSlice";
import userProSlice from "./userPro";
import missionsSlice from "../../common/redux/missionsSlice";

export const store: any = configureStore({
    reducer: {
        auth: authReducer.reducer,
        signUp: signUpSlice.reducer,
        signUpProfessionnel: signUpSliceProfessionnel.reducer,
        user: userSlice.reducer,
        userPro: userProSlice.reducer,
        projects: projectSlice.reducer,
        search: searchSlice.reducer,
        professionnel: professionnelSlice.reducer,
        structure: structureSlice.reducer,
        profil: profilSlice.reducer,
        missions: missionsSlice,
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: false,
        }),
});
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export default store;
