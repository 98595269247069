import { Button, styled } from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { t } from "i18next";

interface SortButtonProps {
    sortOrder: string;
    setSortOrder: (order: string) => void;
}

const SortButton = ({ sortOrder, setSortOrder }: SortButtonProps) => {
    const toggleSortDirection = () => {
        const newDirection = sortOrder === "desc" ? "asc" : "desc";
        setSortOrder(newDirection);
    };

    return (
        <StyledButton variant="contained" onClick={toggleSortDirection}>
            {sortOrder === "desc"
                ? t("missions.sort.button")
                : t("missions.sort.button.ascending")}
            {sortOrder === "desc" ? (
                <KeyboardArrowDownIcon />
            ) : (
                <KeyboardArrowUpIcon />
            )}
        </StyledButton>
    );
};

export default SortButton;

const StyledButton = styled(Button)({
    width: "fit-content",
});
