import { calculateMissionStatus } from "./StatsStatus";

export function combineData(
    missions: any[],
    statistics: any[],
    userMissions: any[],
    t: (key: string) => string
) {
    return missions.map((mission) => {
        const stats = statistics.find((stat) => stat.id === mission.id);
        const userMission = userMissions.find((user) => user.id === mission.id);

        const status = calculateMissionStatus(
            stats?.startDate?.date || mission.startDate,
            stats?.endDate?.date || mission.endDate,
            t
        );

        return {
            ...mission,
            ...stats,
            status,
            ref: userMission?.ref || "N/A",
        };
    });
}
