import { useEffect, useState, useCallback } from "react";
import {
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
    Stack,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import KeyboardDoubleArrowDownIcon from "@mui/icons-material/KeyboardDoubleArrowDown";
import dayjs from "dayjs";
import isBetween from "dayjs/plugin/isBetween";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { useAppDispatch } from "../../common/redux/reduxHooks";
import styled from "@emotion/styled";
import {
    getMissionStatistics,
    getMissionById,
} from "../../common/redux/missionActions";
import CustomPagination from "./Pagination";

import FilterStatisticsDashboard from "./FilterStatisticsDashboard";
import { combineData } from "../utils/combineData";

dayjs.extend(isBetween);

export const StructureDashboard = () => {
    const [missions, setMissions] = useState<any[]>([]);
    const [totalAmount, setTotalAmount] = useState(0);
    const [filteredMissions, setFilteredMissions] = useState<any[]>([]);
    const [startDate, setStartDate] = useState<Date | null>(
        dayjs().startOf("month").toDate()
    );
    const [endDate, setEndDate] = useState<Date | null>(
        dayjs().endOf("month").toDate()
    );
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage] = useState(5);

    const dispatch = useAppDispatch();
    const { t } = useTranslation();

    const fetchMissionStatistics = useCallback(async () => {
        try {
            const missionStatisticsResult = await dispatch(
                getMissionStatistics({
                    startDate: startDate ? dayjs(startDate).unix() : undefined,
                    endDate: endDate ? dayjs(endDate).unix() : undefined,
                })
            );
            const missionStatisticsData = Array.isArray(
                missionStatisticsResult.payload
            )
                ? missionStatisticsResult.payload
                : [];

            const userMissionPromises = missionStatisticsData.map(
                (mission: { id: string }) =>
                    dispatch(
                        getMissionById({
                            id: mission.id,
                            role: "ROLE_STRUCTURE",
                        })
                    )
            );
            const userMissionResults = await Promise.all(userMissionPromises);
            const userMissionData = userMissionResults.map(
                (result) => result.payload
            );

            const combinedData = combineData(
                missionStatisticsData,
                missionStatisticsData,
                userMissionData,
                t
            );

            setMissions(combinedData);
            setFilteredMissions(combinedData);

            const total = combinedData.reduce(
                (sum: number, mission: { totals: { amount: number } }) =>
                    sum + (mission.totals?.amount || 0),
                0
            );
            setTotalAmount(total);
        } catch (error) {
            console.error(
                "Error fetching mission statistics or user missions:",
                error
            );
        }
    }, [dispatch, t]);

    useEffect(() => {
        fetchMissionStatistics();
    }, [fetchMissionStatistics]);

    const applyDefaultFilter = (data: any[]) => {
        if (!startDate || !endDate) {
            return;
        }

        const filterStart = new Date(startDate).getTime();
        const filterEnd = new Date(endDate).getTime();

        const filtered = data.filter((mission) => {
            const missionStart = new Date(mission.startDate.date).getTime();
            const missionEnd = new Date(mission.endDate.date).getTime();

            return (
                (missionStart >= filterStart && missionStart <= filterEnd) ||
                (missionEnd >= filterStart && missionEnd <= filterEnd) ||
                (missionStart <= filterStart && missionEnd >= filterEnd)
            );
        });

        setFilteredMissions(filtered);
        setCurrentPage(1);
    };

    useEffect(() => {
        if (missions.length > 0) {
            applyDefaultFilter(missions);
        }
    }, [startDate, endDate, missions]);

    const handleClearFilter = () => {
        const startOfMonth = dayjs().startOf("month").toDate();
        const endOfMonth = dayjs().endOf("month").toDate();
        setStartDate(startOfMonth);
        setEndDate(endOfMonth);
        applyDefaultFilter(missions);
    };

    const paginatedMissions = filteredMissions.slice(
        (currentPage - 1) * itemsPerPage,
        currentPage * itemsPerPage
    );

    const getStatusStyle = (status: string) => ({
        backgroundColor:
            {
                [t("mission.status.progress")]: "#F28C28",
                [t("mission.status.past")]: "#008200",
                [t("mission.status.upcoming")]: "#818589",
            }[status] || "#899499",
    });

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Stack
                width="100%"
                display="flex"
                justifyContent="center"
                alignItems="center"
            >
                <AccordionContainer>
                    <AccordionSummary
                        expandIcon={
                            <KeyboardDoubleArrowDownIcon
                                fontSize="large"
                                sx={{ color: "black" }}
                            />
                        }
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                        sx={{ height: "auto" }}
                    >
                        <AccordionSummaryStatus>
                            <DashboardTitle>
                                {t("mission.dashboard.title")}
                            </DashboardTitle>
                        </AccordionSummaryStatus>
                    </AccordionSummary>
                    <AccordionDetails>
                        <DashboardContainer>
                            <FilterStatisticsDashboard
                                startDate={startDate}
                                endDate={endDate}
                                onStartDateChange={setStartDate}
                                onEndDateChange={setEndDate}
                                onClear={handleClearFilter}
                            />
                            <TableContainer>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableTitle>
                                                {t("mission.dashboard.mission")}
                                            </TableTitle>
                                            <TableTitle>
                                                {t("missions.billable")}
                                            </TableTitle>
                                            <TableTitle>
                                                {t("mission.dashboard.time")}
                                            </TableTitle>
                                            <TableTitle>
                                                {t("mission.dashboard.amount")}
                                            </TableTitle>
                                            <TableTitle>
                                                {t("mission.status.status")}
                                            </TableTitle>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {filteredMissions.length > 0 ? (
                                            paginatedMissions.map((mission) => (
                                                <TableRow key={mission.id}>
                                                    <TableInfo>
                                                        {mission.ref}
                                                    </TableInfo>
                                                    <TableInfo>
                                                        {mission.dailyBilling
                                                            ? t(
                                                                  "mission.dashboard.daily"
                                                              )
                                                            : t(
                                                                  "mission.dashboard.hourly"
                                                              )}
                                                    </TableInfo>
                                                    <TableInfo>
                                                        {mission.dailyBilling
                                                            ? `${
                                                                  mission.totals
                                                                      ?.time ||
                                                                  "N/A"
                                                              } ${t(
                                                                  "mission.dashboard.days"
                                                              )}`
                                                            : `${
                                                                  mission.totals
                                                                      ?.time ||
                                                                  "N/A"
                                                              } Hrs`}
                                                    </TableInfo>
                                                    <TableInfo>
                                                        {mission.totals?.amount
                                                            ? `${mission.totals.amount.toFixed(
                                                                  2
                                                              )} €`
                                                            : "N/A"}
                                                    </TableInfo>
                                                    <TableStatus
                                                        sx={{
                                                            backgroundColor:
                                                                getStatusStyle(
                                                                    mission.status
                                                                ),
                                                        }}
                                                    >
                                                        {mission.status}
                                                    </TableStatus>
                                                </TableRow>
                                            ))
                                        ) : (
                                            <TableRow>
                                                <TableCell
                                                    colSpan={5}
                                                    align="center"
                                                >
                                                    <Typography>
                                                        {t(
                                                            "mission.message.notfound.mission"
                                                        )}
                                                    </Typography>
                                                </TableCell>
                                            </TableRow>
                                        )}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <CustomPagination
                                totalItems={filteredMissions.length}
                                itemsPerPage={itemsPerPage}
                                currentPage={currentPage}
                                onPageChange={setCurrentPage}
                            />
                        </DashboardContainer>
                    </AccordionDetails>
                </AccordionContainer>
            </Stack>
        </LocalizationProvider>
    );
};

const AccordionContainer = styled(Accordion)({
    width: "100%",
    margin: "20px 0 40px 0",
    backgroundColor: "#f7f7f7",
    boxShadow:
        "0px 2px 4px rgba(0, 0, 0, 0.4), 0px 7px 7px -3px rgba(0, 0, 0, 0.15), inset 0px -3px 0px rgba(0, 0, 0, 0.2)",
    "@media(width >= 990px)": {
        width: "80%",
    },
});

const DashboardContainer = styled("div")({
    width: "100%",
    padding: "20px",
    backgroundColor: "#FAF9F6",
    borderRadius: "8px",
    boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.15)",
});

const DashboardTitle = styled(Typography)({
    fontFamily: "Montserrat",
    fontSize: "16px",
    textAlign: "start",
    padding: "5px",
    paddingRight: "10px",
    borderRight: "1px solid #E0E0E0",

    "@media(width >= 768px)": {
        fontSize: "18px",
        padding: "20px",
    },
});

const AccordionSummaryStatus = styled(Stack)({
    display: "flex",
    flexDirection: "column",
    alignItems: "start",
    width: "100%",
    "@media(width >= 768px)": {
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "start",
    },
});

const TableTitle = styled(TableCell)({
    fontSize: "12.5px",
    "@media(width >= 768px)": {
        fontSize: "14px",
    },
});

const TableInfo = styled(TableCell)({
    fontSize: "10px",
    textAlign: "center",
    "@media(width >= 768px)": {
        textAlign: "start",
        fontSize: "13px",
    },
});

const TableStatus = styled(TableCell)({
    fontSize: "10px",
    color: "#FAF9F6",
    textAlign: "center",
    borderRadius: "5px",
    "@media(width >= 768px)": {
        width: "90px",
    },
    "@media(width >= 990px)": {
        width: "120px",
        fontSize: "14px",
    },
});
