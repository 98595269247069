import { useState } from "react";
import { Snackbar, Alert } from "@mui/material";

export const useValidation = () => {
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState("");
    const [snackbarSeverity, setSnackbarSeverity] = useState<
        "success" | "error"
    >("error");

    const showSnackbar = (message: string, severity: "success" | "error") => {
        setSnackbarMessage(message);
        setSnackbarSeverity(severity);
        setSnackbarOpen(true);
    };

    const showSuccess = (message: string) => showSnackbar(message, "success");
    const showError = (message: string) => showSnackbar(message, "error");

    const closeSnackbar = () => {
        setSnackbarOpen(false);
    };

    const SnackbarComponent = (
        <Snackbar
            open={snackbarOpen}
            autoHideDuration={6000}
            onClose={closeSnackbar}
        >
            <Alert
                onClose={closeSnackbar}
                severity={snackbarSeverity}
                sx={{ width: "100%" }}
            >
                {snackbarMessage}
            </Alert>
        </Snackbar>
    );

    return { showSnackbar, showSuccess, showError, SnackbarComponent };
};
