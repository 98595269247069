import React from "react";
import { Box, Button, ListItemText, ListItemButton, List } from "@mui/material";
import { useNavigate, useLocation } from "react-router-dom";
import { RouteApp } from "../model/RouteApp";
import { language, LanguageType } from "../../utils/LanguageUtils";
import LogoDial from "../../assets/LogoDial.png";
import styled from "@emotion/styled";

interface MobileMenuProps {
    isStructure: boolean;
    handleDrawerToggle: () => void;
    handleClose: (type: string) => void;
    userSelector: any;
    t: any;
}

export const MobileMenu: React.FC<MobileMenuProps> = ({
    isStructure,
    handleDrawerToggle,
    handleClose,
    userSelector,
    t,
}) => {
    const navigate = useNavigate();
    const location = useLocation();
    const lang: LanguageType = language();

    return (
        <Box
            onClick={handleDrawerToggle}
            sx={{ textAlign: "center", width: "100%" }}
        >
            <Button
                sx={{
                    width: "100%",
                    justifyContent: "center",
                    alignItems: "center",
                    padding: "0",
                }}
            >
                <Box
                    component="img"
                    sx={{ height: 62 }}
                    alt="Smart Sante"
                    className="center"
                    src={LogoDial}
                    onClick={() => navigate(RouteApp.DASHBOARD)}
                />
            </Button>
            <ListNavbar>
                {isStructure ? (
                    <>
                        <NavLink
                            onClick={() => navigate(RouteApp.MY_ACCOUNT)}
                            selected={location.pathname === RouteApp.MY_ACCOUNT}
                        >
                            <ListItemText primary={lang.navbar.profile} />
                        </NavLink>
                        <NavLink
                            onClick={() => navigate(RouteApp.DASHBOARD)}
                            selected={location.pathname === RouteApp.DASHBOARD}
                        >
                            <ListItemText primary={lang.navbar.dashboard} />
                        </NavLink>
                        <NavLink
                            onClick={() => navigate(RouteApp.SEARCH)}
                            selected={location.pathname === RouteApp.SEARCH}
                        >
                            <ListItemText primary={lang.navbar.personnel} />
                        </NavLink>
                        <NavLink
                            onClick={() => navigate(RouteApp.PROFESSIONNELS)}
                            selected={
                                location.pathname === RouteApp.PROFESSIONNELS
                            }
                        >
                            <ListItemText primary={lang.navbar.contact} />
                        </NavLink>
                        <NavLink
                            onClick={() => navigate(RouteApp.MY_PROJECTS)}
                            selected={
                                location.pathname === RouteApp.MY_PROJECTS
                            }
                        >
                            <ListItemText primary={lang.navbar.projet} />
                        </NavLink>
                        <NavLink
                            onClick={() => navigate(RouteApp.MISSION_BROWSER)}
                            selected={
                                location.pathname === RouteApp.MISSION_BROWSER
                            }
                        >
                            <ListItemText primary={lang.navbar.mission} />
                        </NavLink>
                    </>
                ) : (
                    <>
                        <NavLink
                            onClick={() => navigate(RouteApp.MY_ACCOUNT)}
                            selected={location.pathname === RouteApp.MY_ACCOUNT}
                        >
                            <ListItemText primary={lang.navbar.profile} />
                        </NavLink>
                        <NavLink
                            onClick={() => navigate(RouteApp.DASHBOARD)}
                            selected={location.pathname === RouteApp.DASHBOARD}
                        >
                            <ListItemText primary={lang.navbar.dashboard} />
                        </NavLink>
                        <NavLink
                            onClick={() => navigate(RouteApp.SEARCH)}
                            selected={location.pathname === RouteApp.SEARCH}
                        >
                            <ListItemText primary={lang.navbar.structures} />
                        </NavLink>
                        <NavLink
                            onClick={() => navigate(RouteApp.PROFESSIONNELS)}
                            selected={
                                location.pathname === RouteApp.PROFESSIONNELS
                            }
                        >
                            <ListItemText primary={lang.navbar.contact} />
                        </NavLink>
                        <NavLink
                            onClick={() => navigate(RouteApp.MY_PROJECTS)}
                            selected={
                                location.pathname === RouteApp.MY_PROJECTS
                            }
                        >
                            <ListItemText primary={lang.navbar.disponibilite} />
                        </NavLink>
                        <NavLink
                            onClick={() => navigate(RouteApp.MISSION_BROWSER)}
                            selected={
                                location.pathname === RouteApp.MISSION_BROWSER
                            }
                        >
                            <ListItemText primary={lang.navbar.mission} />
                        </NavLink>
                    </>
                )}
                <NavLink onClick={() => handleClose("Deconnexion")}>
                    <ListItemText>
                        {t("button.profile.disconnect")}
                    </ListItemText>
                </NavLink>
            </ListNavbar>
        </Box>
    );
};

const NavLink = styled(ListItemButton)({
    backgroundColor: "white",
    borderRadius: "5px",
    width: "100%",
});

const ListNavbar = styled(List)({
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    gap: "0.5rem",
    width: "100%",
});
