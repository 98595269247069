export const RouteApp = {
    LOGIN: "/login",
    SIGNUP: "/signup",
    DASHBOARD: "/dashboard",
    MY_ACCOUNT: "/profil",
    SEARCH: "/search",
    MY_PROJECTS: "/projects",
    MY_PROJECTS_DETAILS: "/projects/details",
    MANAGE_PROJECTS: "/projects/manage",
    PROFESSIONNELS: "/personnels",
    FORGOT_PASSWORD: "/reset",
    RESET_PASSWORD: "/reset-password",
    LEGACY: "/informations-legales",
    STRUCTURES: "/structures",
    DISPONIBILITE: "/disponibilite",
    MISSION_BROWSER: "/mission/browse",
    MISSION_DETAILS_PRO: "/mission-details-professional/:id",
    MISSION_DETAILS_STR: "/mission-details-structure/:id",
};
