import { useEffect, useMemo, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { Card, Divider, Typography, Box, Button, Stack } from "@mui/material";
import { useValidation } from "../hooks/useValidation";
import NextWeekIcon from "@mui/icons-material/NextWeek";
import AvTimerIcon from "@mui/icons-material/AvTimer";
import EditIcon from "@mui/icons-material/Edit";
import PaymentIcon from "@mui/icons-material/Payment";
import TodayIcon from "@mui/icons-material/Today";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import PriorityHighIcon from "@mui/icons-material/PriorityHigh";
import AlarmOnIcon from "@mui/icons-material/AlarmOn";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import styled from "@emotion/styled";
import { formatTimeHHMM, getApproved, getStatus } from "../utils/utils";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import MedicalServicesIcon from "@mui/icons-material/MedicalServices";
import { RouteApp } from "../../common/model/RouteApp";
import { useTranslation } from "react-i18next";
import { TimesheetModal } from "./TimeSheetModal";
import { calculateWorkingHours, useTotal } from "../hooks/useTotal";
import EditTimesheet from "./EditTimesheet";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../common/redux/store";

import {
    deleteTimeSheet,
    getMissionById,
    updateTimeSheet,
} from "../../common/redux/missionActions";
import MultipleTimesheet from "./MultipleTimesheet";

export interface Timesheet {
    id: string;
    startDate: string;
    endDate: string;
    dailyWorkingTime?: number;
    cost?: number;
    status: string;
    billable: number;
}

export const MissionProCard = () => {
    const [editModalVisible, setEditModalVisible] = useState(false);
    const [modalVisible, setModalVisible] = useState(false);
    const { showSuccess, SnackbarComponent } = useValidation();
    const [currentEdit, setCurrentEdit] = useState<string | null>(null);
    const [localTimesheets, setLocalTimesheets] = useState<Timesheet[]>([]);
    const mission = useSelector(
        (state: RootState) => state.missions.selectedMission
    );

    const { t } = useTranslation();
    const { id } = useParams<{ id: string }>();
    const dispatch = useDispatch<AppDispatch>();

    const { processedTimesheets, totalDailyWorkingTime, totalCost } =
        useTotal(mission);

    useEffect(() => {
        if (id) {
            dispatch(getMissionById({ id, role: "ROLE_PROFESSIONNEL" }));
        }
    }, [id, dispatch]);

    useEffect(() => {
        if (mission.timeSheets !== localTimesheets) {
            setLocalTimesheets(mission.timeSheets);
        }
    }, [localTimesheets, mission.timeSheets]);

    const groupedTimesheets = useMemo(() => {
        return processedTimesheets.reduce(
            (groups: Record<string, Timesheet[]>, ts: Timesheet) => {
                const date = new Date(ts.startDate).toLocaleDateString(
                    "fr-FR",
                    {
                        day: "2-digit",
                        month: "2-digit",
                        year: "numeric",
                    }
                );

                if (!groups[date]) {
                    groups[date] = [];
                }
                groups[date].push(ts);

                return groups;
            },
            {}
        );
    }, [processedTimesheets]);

    const combinedItems = useMemo(() => {
        const items: Array<{
            type: "individual" | "multiple";
            timesheet?: Timesheet;
            timesheets?: Timesheet[];
            startDate: Date;
        }> = [];

        Object.entries(groupedTimesheets).forEach(([_, timesheets]) => {
            const timesheetArray = timesheets as Timesheet[];

            if (timesheetArray.length > 1) {
                items.push({
                    type: "multiple",
                    timesheets: timesheetArray,
                    startDate: new Date(timesheetArray[0].startDate),
                });
            } else {
                items.push({
                    type: "individual",
                    timesheet: timesheetArray[0],
                    startDate: new Date(timesheetArray[0].startDate),
                });
            }
        });
        return items.sort(
            (a, b) => b.startDate.getTime() - a.startDate.getTime()
        );
    }, [groupedTimesheets]);

    const handleAddTimesheetSuccess = (newTimesheet: Timesheet) => {
        showSuccess(t("mission.success.add.timesheet"));
        setLocalTimesheets((prev) => [...prev, newTimesheet]);
        if (id) dispatch(getMissionById({ id, role: "ROLE_PROFESSIONNEL" }));
    };

    const handleEdit = (
        timesheetId: string | null,
        updatedTimesheet: Timesheet
    ) => {
        if (timesheetId) {
            const { startDate, endDate } = updatedTimesheet;
            if (startDate && endDate) {
                setLocalTimesheets((prev) =>
                    prev.map((ts) =>
                        ts.id === timesheetId
                            ? { ...ts, startDate, endDate }
                            : ts
                    )
                );
                dispatch(
                    updateTimeSheet({
                        id: timesheetId,
                        startDate,
                        endDate,
                    })
                ).then(() => {
                    showSuccess(t("mission.edit.timesheet"));
                    if (id) {
                        dispatch(
                            getMissionById({
                                id,
                                role: "ROLE_PROFESSIONNEL",
                            })
                        );
                    }
                });
            }
        }
        setEditModalVisible(false);
    };

    const handleEditTimesheet = (timesheetId: string) => {
        if (
            processedTimesheets.some(
                (ts: { id: string }) => ts.id === timesheetId
            )
        ) {
            setCurrentEdit(timesheetId);
            setEditModalVisible(true);
        }
    };

    const handleDeleteTimesheet = (timesheetId: string) => {
        dispatch(deleteTimeSheet(timesheetId))
            .then(() => {
                setLocalTimesheets((prev) =>
                    prev.filter((ts) => ts.id !== timesheetId)
                );
                showSuccess(t("mission.deleted.timesheet"));
            })
            .finally(
                () =>
                    id &&
                    dispatch(getMissionById({ id, role: "ROLE_PROFESSIONNEL" }))
            );
    };

    const handleCloseEditModal = () => {
        setEditModalVisible(false);
        setCurrentEdit(null);
    };

    return (
        <PageWrapper>
            <Stack marginTop="5rem">
                <StyledLink to={RouteApp.MISSION_BROWSER}>
                    <ArrowBackIcon fontSize="large" /> {t("missions.back.link")}
                </StyledLink>
            </Stack>
            <Container>
                <Card
                    sx={{
                        padding: 2,
                        display: "flex",
                        flexDirection: "column",
                        gap: 1,
                        backgroundColor: "#f7f7f7",
                    }}
                >
                    <Stack
                        flexDirection="row"
                        alignItems="center"
                        justifyContent="space-between"
                    >
                        <Reference fontWeight="bold">{mission.ref}</Reference>
                        <Status
                            sx={{
                                backgroundColor: getStatus(
                                    mission.timeSheets.status
                                ),
                            }}
                        >
                            {mission.timeSheets.status}
                        </Status>
                    </Stack>
                    <Structure>{mission.structure.societe}</Structure>
                    <Structure>{mission.name}</Structure>
                    <Notes>{mission.notes}</Notes>
                    <Profession>
                        <MedicalServicesIcon />
                        {mission.job}
                    </Profession>
                    <Divider sx={{ marginY: 2 }} />
                    <Stack
                        sx={{
                            flexDirection: "row",
                            width: "100%",
                            alignItems: "center",
                            justifyContent: "center",
                            gap: "1rem",
                        }}
                    >
                        <Typography variant="body1">
                            {new Date(mission.startDate).toLocaleDateString()}
                        </Typography>
                        <NextWeekIcon fontSize="large" />
                        <Typography variant="body1">
                            {new Date(mission.endDate).toLocaleDateString()}
                        </Typography>
                    </Stack>
                    <Divider sx={{ marginY: 2 }} />
                    <SheetTitle>{t("missions.time.sheet.title")}</SheetTitle>

                    <Divider
                        orientation="horizontal"
                        flexItem
                        variant="fullWidth"
                        sx={{ marginTop: "1rem" }}
                    />
                    {mission.dailyBilling && (
                        <NotifyDailyBilling color="textSecondary">
                            <PriorityHighIcon
                                fontSize="large"
                                sx={{ color: "#E53935" }}
                            />
                            {t("mission.disclaimer")}
                            <PriorityHighIcon
                                fontSize="large"
                                sx={{ color: "#E53935" }}
                            />
                        </NotifyDailyBilling>
                    )}
                    <Stack
                        flexDirection="row"
                        flex="1"
                        justifyContent="space-between"
                    >
                        <SheetTitleColumn>
                            <span className="text">
                                {t("select.date.start")}
                            </span>
                            <TodayIcon className="icon" />
                        </SheetTitleColumn>
                        <SheetTitleColumn>
                            <span className="text">
                                {t("missions.hour.start")}
                            </span>
                            <AccessTimeIcon className="icon" />
                        </SheetTitleColumn>
                        <SheetTitleColumn>
                            <span className="text">
                                {t("missions.hour.end")}
                            </span>
                            <AlarmOnIcon className="icon" />
                        </SheetTitleColumn>
                        <SheetTitleColumn>
                            <span className="text">
                                {t("missions.billable.time")}
                            </span>
                            <AvTimerIcon className="icon" />
                        </SheetTitleColumn>
                        <SheetTitleColumn>
                            <span className="text">
                                {t("missions.billable.amount")}
                            </span>
                            <PaymentIcon className="icon" />
                        </SheetTitleColumn>
                        <SheetTitleColumn>
                            {t("mission.status.status")}
                        </SheetTitleColumn>
                        <SheetTitleColumn>MODIF</SheetTitleColumn>
                    </Stack>
                    <Divider
                        orientation="horizontal"
                        flexItem
                        variant="fullWidth"
                        sx={{ marginBottom: "1rem" }}
                    />

                    {localTimesheets && localTimesheets.length > 0 ? (
                        <>
                            {combinedItems.map((item, index) =>
                                item.type === "individual" && item.timesheet ? (
                                    <SheetBox
                                        key={index}
                                        sx={{
                                            backgroundColor:
                                                index % 2 === 0
                                                    ? "#f7f7f7"
                                                    : "white",
                                        }}
                                    >
                                        <StyledStack>
                                            <SheetDetails>
                                                {new Date(
                                                    item.timesheet.startDate
                                                ).toLocaleDateString("fr-FR", {
                                                    day: "2-digit",
                                                    month: "2-digit",
                                                })}
                                            </SheetDetails>
                                            <SheetDetails>
                                                {new Date(
                                                    item.timesheet.startDate
                                                )
                                                    .toLocaleTimeString()
                                                    .slice(0, -3)}
                                            </SheetDetails>
                                            <SheetDetails>
                                                {new Date(
                                                    item.timesheet.endDate
                                                )
                                                    .toLocaleTimeString()
                                                    .slice(0, -3)}
                                            </SheetDetails>
                                            <SheetDetails>
                                                {calculateWorkingHours(
                                                    item.timesheet.startDate,
                                                    item.timesheet.endDate,
                                                    true
                                                )}
                                            </SheetDetails>
                                            <SheetDetails>
                                                {item.timesheet.cost}€
                                            </SheetDetails>
                                            <Typography
                                                justifySelf="center"
                                                sx={{
                                                    backgroundColor:
                                                        getApproved(
                                                            t,
                                                            item.timesheet
                                                                .status
                                                        ).backgroundColor,
                                                    display: "flex",
                                                    alignItems: "center",
                                                    color: "#FAF9F6",
                                                    justifyContent: "center",
                                                    borderRadius: "5px",
                                                    fontSize: "9px",
                                                    flex: "1",
                                                    "@media(width >= 768px)": {
                                                        fontSize: "12px",
                                                    },
                                                }}
                                            >
                                                {
                                                    getApproved(
                                                        t,
                                                        item.timesheet.status
                                                    ).label
                                                }
                                            </Typography>
                                            <SheetDetails>
                                                <EditButton
                                                    onClick={() =>
                                                        handleEditTimesheet(
                                                            item.timesheet!.id
                                                        )
                                                    }
                                                    disabled={
                                                        item.timesheet
                                                            .status ===
                                                        "approved"
                                                    }
                                                >
                                                    <EditIcon fontSize="small" />
                                                </EditButton>
                                            </SheetDetails>
                                        </StyledStack>
                                    </SheetBox>
                                ) : (
                                    <MultipleTimesheet
                                        key={index}
                                        date={item.startDate.toLocaleDateString(
                                            "fr-FR"
                                        )}
                                        timesheet={item.timesheets || []}
                                        index={index}
                                        missionId={mission.id}
                                    />
                                )
                            )}

                            <Stack flex="1" marginTop="1rem" gap="1rem">
                                <Stack
                                    display="flex"
                                    flexDirection="row"
                                    justifyContent="start"
                                    alignItems="center"
                                    gap="10px"
                                >
                                    <Typography>TOTAL: </Typography>
                                    {!mission.dailyBilling && (
                                        <Typography
                                            display="flex"
                                            alignItems="center"
                                            gap="0.25rem"
                                        >
                                            <AvTimerIcon />
                                            {formatTimeHHMM(
                                                totalDailyWorkingTime
                                            )}
                                        </Typography>
                                    )}

                                    {!mission.dailyBilling && (
                                        <ArrowForwardIcon />
                                    )}
                                    <Typography
                                        display="flex"
                                        alignItems="center"
                                        gap="0.25rem"
                                    >
                                        <PaymentIcon />
                                        {totalCost.toFixed(2)}€
                                    </Typography>
                                </Stack>

                                <Divider orientation="horizontal" />
                            </Stack>
                        </>
                    ) : (
                        <Typography
                            variant="body2"
                            color="textSecondary"
                            align="center"
                            mt={2}
                        >
                            {t("alert.timesheet.notfound")}
                        </Typography>
                    )}
                    <Button
                        onClick={() => setModalVisible(true)}
                        variant="contained"
                        color="primary"
                        sx={{ marginTop: "2rem" }}
                    >
                        {t("missions.add.timesheet")}
                    </Button>
                </Card>
                <TimesheetModal
                    isOpen={modalVisible}
                    onClose={() => setModalVisible(false)}
                    missionId={mission.id}
                    onSuccess={handleAddTimesheetSuccess}
                    missionStartDate={mission.startDate}
                    missionEndDate={mission.endDate}
                />
                <EditTimesheet
                    isOpen={editModalVisible}
                    onClose={handleCloseEditModal}
                    timesheet={
                        currentEdit
                            ? processedTimesheets.find(
                                  (ts: { id: string }) => ts.id === currentEdit
                              ) || {}
                            : {}
                    }
                    handleSubmit={handleEdit}
                    timesheetId={currentEdit}
                    handleDeleteTimesheet={handleDeleteTimesheet}
                    missionStartDate={mission.startDate}
                    missionEndDate={mission.endDate}
                />
                {SnackbarComponent}
            </Container>
        </PageWrapper>
    );
};

const PageWrapper = styled("div")({
    display: "flex",
    flexDirection: "column",
    minHeight: "100dvh",
    marginBottom: "2rem",
});

const Container = styled(Box)({
    height: "auto",
    width: "100%",
    margin: "3rem auto",

    "@media(width >= 768px)": {
        width: "70%",
    },

    "@media(width >= 990px)": {
        width: "60%",
    },
});

const Reference = styled(Typography)({
    fontSize: "14px",
    fontWeight: "bold",
    color: "#555555",
});

const Structure = styled(Typography)({
    fontSize: "16px",
    color: "#E53935",
    fontFamily: "Montserrat",
    width: "100%",
    textAlign: "center",
    marginTop: "1rem",

    "@media(width >= 990px)": {
        fontSize: "24px",
    },
});

const Profession = styled(Typography)({
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    gap: "5px",
    marginTop: "1rem",
    fontsize: "14px",
});

const SheetBox = styled(Box)({
    display: "flex",
    textAlign: "center",
    flexDirection: "column",
    marginTop: "0.25rem",
});

const SheetTitle = styled(Typography)({
    fontSize: "14px",
    fontWeight: "bold",

    "@media(width >= 990px)": {
        fontSize: "16px",
    },
});

const SheetTitleColumn = styled(Typography)({
    display: "flex",
    fontSize: "11px",
    alignItems: "center",
    justifyContent: "center",
    flex: "1",

    "@media (width <= 989px)": {
        ".text": { display: "none" },
    },
    "@media (width >= 990px)": {
        ".icon": { display: "none" },
        fontSize: "13px",
        flex: "0 0 14%",
        textAlign: "center",
    },
});

const Notes = styled(Typography)({
    fontSize: "14px",
    color: "#555555",
    fontFamily: "Montserrat",
    width: "100%",
    textAlign: "center",
    marginTop: "1rem",
});

const NotifyDailyBilling = styled(Typography)({
    backgroundColor: "#f1f1f1",
    padding: "1rem",
    borderRadius: "5px",
    marginBottom: "1rem",
    textAlign: "center",
    display: "flex",
    fontSize: "12px",
    alignItems: "center",
    justifyContent: "center",

    "@media(width >= 768px)": {
        fontSize: "14px",
    },
});

const SheetDetails = styled(Typography)({
    fontSize: "11px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flex: "1",
    padding: "1px",
    "@media(width >= 768px)": {
        fontSize: "14px",
    },
});

const EditButton = styled(Button)({
    margin: 0,
    width: "25px !important",
    minWidth: "25px !important",
    padding: "2px",
});

const Status = styled(Typography)({
    padding: 8,
    borderRadius: "15px",
    marginTop: "5px",
    color: "white",
    fontSize: "14px",
    textAlign: "center",
    width: "fit-content",
});

const StyledLink = styled(Link)({
    display: "flex",
    alignItems: "center",
    marginLeft: "2rem",
    fontSize: "16px",
    fontWeight: "bold",
    fontFamily: "Montserrat",
    color: "black",
    gap: "10px",
    textDecoration: "none",

    "@media(width >= 990px)": {
        fontSize: "20px",
    },
});

const StyledStack = styled(Stack)({
    flexDirection: "row",
    justifyContent: "space-between",
    flex: "1",
});
