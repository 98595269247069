import { createAsyncThunk } from "@reduxjs/toolkit";
import { ProfessionnelMission } from "../../missions/model/professionnelTypes";
import { ResponseMessage } from "../model/ResponseMessage";
import { StructureMission } from "../../missions/model/structureTypes";
import { api, RouteAPI } from "../service/apiService";
import {
    mapProfessionnelMission,
    mapStructureMission,
} from "../../missions/utils/missionMapperRole";
import { AxiosError } from "axios";

interface FetchMissionsParams {
    page: number;
    itemsPerPage: number;
    sortOrder: string;
    search: string;
}

export const userMissions = createAsyncThunk<
    ProfessionnelMission[],
    FetchMissionsParams & { role: string }
>(
    "missions/userMissions",
    async ({ page, itemsPerPage, sortOrder, search, role }, thunkAPI) => {
        try {
            const response = await api<
                (ProfessionnelMission | StructureMission)[]
            >(
                `${RouteAPI.MISSIONS}?page=${page}&itemsPerPage=${itemsPerPage}&order[startDate]=${sortOrder}&search=${search}`,
                "GET"
            );

            const data =
                role === "ROLE_PROFESSIONNEL"
                    ? response.data.map(mapProfessionnelMission)
                    : response.data.map(mapStructureMission);

            return data;
        } catch (error) {
            return thunkAPI.rejectWithValue(error as AxiosError);
        }
    }
);

export const getMissionById = createAsyncThunk<
    ProfessionnelMission | StructureMission,
    { id: string; role: string }
>("missions/getMissionById", async ({ id, role }, thunkAPI) => {
    try {
        const response = await api<ProfessionnelMission | StructureMission>(
            `${RouteAPI.MISSIONS}/${id}`,
            "GET"
        );

        const data =
            role === "ROLE_PROFESSIONNEL"
                ? mapProfessionnelMission(response.data)
                : mapStructureMission(response.data);

        return data;
    } catch (error) {
        return thunkAPI.rejectWithValue(error as ResponseMessage);
    }
});
