import { AxiosError, Method } from "axios";
import { ResponseMessage } from "./ResponseMessage";
import { ResponseViolations } from "../../structures/redux/signUpAction";
import { ProfessionnelMission } from "../../missions/model/professionnelTypes";

export interface IStateReducer<T> {
    isLoader: boolean;
    isSuccess?: boolean;
    isExtraField?: boolean;
    isCreateProject?: boolean;
    requestType?: Method;
    data: T;
    error?: AxiosError<ResponseViolations | ResponseMessage>;
    isErrorPasswordRequest?: AxiosError<ResponseViolations | ResponseMessage>;
    isSuccessPasswordConfirm?: boolean;
    isErrorPasswordConfirm?: boolean;
    isSuccessPasswordRequest?: boolean;
    isLoggedIn: boolean;
    selectedMission?: ProfessionnelMission | null;
}

/**
 * Initial State Reducer
 */
export const initialStateReducer = {
    isLoader: false,
    isSuccess: false,
    error: undefined,
    isLoggedIn: false,
    selectedMission: null,
};
