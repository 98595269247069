import { useMemo } from "react";
import { Timesheet } from "../components/MissionStructCard";

export function calculateWorkingHours(
    startDate: string,
    endDate: string,
    format = false,
    billable?: number,
    cost?: number
): string | number {
    if (billable === 0) return format ? "00:00" : 0;
    if (cost === 0) return format ? "00:00" : 0;

    const start = new Date(startDate).getTime();
    const end = new Date(endDate).getTime();
    if (isNaN(start) || isNaN(end)) throw new Error("Invalid date format");

    const hours = (end - start) / (1000 * 60 * 60);
    if (!format) return hours;

    const wholeHours = Math.floor(hours);
    const minutes = Math.round((hours - wholeHours) * 60);
    return `${wholeHours.toString().padStart(2, "0")}:${minutes
        .toString()
        .padStart(2, "0")}`;
}

export function useTotal(mission: any) {
    const { timeSheets, dailyBilling, dailyWorkingTime } = mission;

    const processedTimesheets = useMemo(
        () =>
            timeSheets.map((timeSheet: Timesheet) => ({
                ...timeSheet,
                dailyWorkingTime: dailyBilling
                    ? dailyWorkingTime
                    : calculateWorkingHours(
                          timeSheet.startDate,
                          timeSheet.endDate,
                          false,
                          timeSheet.billable,
                          timeSheet.cost
                      ),
                billing: timeSheet.billable || 0,
            })),
        [timeSheets, dailyBilling, dailyWorkingTime]
    );

    const totals = useMemo(
        () =>
            processedTimesheets.reduce(
                (
                    acc: {
                        totalDailyWorkingTime: number;
                        totalCost: number;
                        totalBillable: number;
                    },
                    { dailyWorkingTime = 0, cost = 0, billable = 0 }: any
                ) => ({
                    totalDailyWorkingTime:
                        acc.totalDailyWorkingTime + dailyWorkingTime,
                    totalCost: acc.totalCost + cost,
                    totalBillable: acc.totalBillable + billable,
                }),
                { totalDailyWorkingTime: 0, totalCost: 0, totalBillable: 0 }
            ),
        [processedTimesheets]
    );

    return { processedTimesheets, ...totals };
}
