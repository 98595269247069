import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../common/redux/store";
import { userMissions } from "../../common/redux/missionActions";
import { ProfessionnelMission } from "../../missions/model/professionnelTypes";
import { useTranslation } from "react-i18next";
import { StructureMission } from "../model/structureTypes";

interface Timesheet {
    id: string;
    startDate: string;
    endDate: string;
    status: string;
}

interface UseFetchMissionsProps {
    page: number;
    itemsPerPage: number;
    sortOrder: string;
    search: string;
    role: string;
}

export const useFetchMissions = ({
    page,
    itemsPerPage,
    sortOrder,
    search,
    role,
}: UseFetchMissionsProps) => {
    const [missions, setMissions] = useState<
        (ProfessionnelMission | StructureMission)[]
    >([]);
    const dispatch = useDispatch<AppDispatch>();
    const [totalItems, setTotalItems] = useState(0);
    const { t } = useTranslation();

    const getStatus = (startDate: string, endDate: string) => {
        const now = new Date();
        const start = new Date(startDate);
        const end = new Date(endDate);

        if (start > now) return t("mission.status.upcoming");
        if (now >= start && now <= end) return t("mission.status.progress");
        return t("mission.status.past");
    };

    const mapToMissions = (
        missions: any[]
    ): (ProfessionnelMission | StructureMission)[] => {
        if (!missions || !Array.isArray(missions)) {
            return [];
        }

        return missions.map((mission) => ({
            id: mission.id,
            ref: mission.ref,
            name: mission.name,
            job: mission.job,
            startDate: mission.startDate,
            endDate: mission.endDate,
            dailyWorkingTime: mission.dailyWorkingTime ?? 0,
            startDayTime: mission.startDayTime,
            endDayTime: mission.endDayTime,
            dailyBilling: mission.dailyBilling,
            status: getStatus(mission.startDate, mission.endDate),
            structure: mission.structure,
            ...(mission.professional && {
                professional: {
                    nom: mission.professional.nom,
                    prenom: mission.professional.prenom,
                },
            }),
            timeSheets:
                mission.timeSheets?.map((timesheet: Timesheet) => ({
                    id: timesheet.id,
                    startDate: timesheet.startDate,
                    endDate: timesheet.endDate,
                    status: timesheet.status,
                })) || [],
        }));
    };

    useEffect(() => {
        const fetchMissions = async () => {
            const result = await dispatch(
                userMissions({
                    page,
                    itemsPerPage,
                    sortOrder,
                    search,
                    role,
                })
            );

            if (result.payload) {
                const mappedMissions = mapToMissions(result.payload);
                setMissions(mappedMissions);

                const totalItemsCalculated = result.payload.length + page;
                setTotalItems(totalItemsCalculated);
            }
        };

        fetchMissions();
    }, [dispatch, page, itemsPerPage, sortOrder, search, t, role]);

    return { missions, totalItems };
};

export default useFetchMissions;
