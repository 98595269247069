import { createSlice } from "@reduxjs/toolkit";
import {
    ProfessionnelMission,
    initialProfessionnelMission,
} from "../../missions/model/professionnelTypes";
import { userMissions, getMissionById } from "./userMission";
import {
    initialStateReducer,
    IStateReducer,
} from "../../common/model/IStateReducer";
import { ResponseMessage } from "../model/ResponseMessage";
import { AxiosError } from "axios";
import { StructureMission } from "../../missions/model/structureTypes";

const initialMissionState: IStateReducer<
    ProfessionnelMission[] | StructureMission
> = {
    ...initialStateReducer,
    data: [initialProfessionnelMission],
    selectedMission: initialProfessionnelMission,
};

const missionsSlice = createSlice({
    name: "missions",
    initialState: initialMissionState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(userMissions.pending, (state) => {
                state.isLoader = true;
                state.error = undefined;
            })
            .addCase(userMissions.fulfilled, (state, action) => {
                state.data = action.payload;
                state.isLoader = false;
            })
            .addCase(userMissions.rejected, (state, action) => {
                state.isLoader = false;
                state.error = action.payload as AxiosError<ResponseMessage>;
            })

            .addCase(getMissionById.pending, (state) => {
                state.isLoader = true;
                state.error = undefined;
            })
            .addCase(getMissionById.fulfilled, (state, action) => {
                state.selectedMission = { ...action.payload };
                state.isLoader = false;
            })
            .addCase(getMissionById.rejected, (state, action) => {
                state.isLoader = false;
                state.error = action.payload as AxiosError<ResponseMessage>;
            });
    },
});

export default missionsSlice.reducer;
