import { ProfessionnelMission } from "../model/professionnelTypes";
import { StructureMission } from "../model/structureTypes";

export const mapProfessionnelMission = (
    mission: any
): ProfessionnelMission => ({
    ...mission,
    isProfessional: true,
});

export const mapStructureMission = (mission: any): StructureMission => ({
    ...mission,
    isStructure: true,
});
