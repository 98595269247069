export interface ProfessionnelMission {
    id: string;
    ref: string;
    name: string;
    job: string;
    startDate: string;
    endDate: string;
    dailyWorkingTime: number;
    startDayTime: string;
    endDayTime: string;
    dailyBilling: boolean;
    status: string;
    professional: {
        nom: string;
        prenom: string;
    };
    structure: {
        societe: string;
        ville: null;
    };
    timeSheets: [
        {
            id: string;
            startDate: string;
            endDate: string;
            status: string;
        }
    ];
}

export const initialProfessionnelMission: ProfessionnelMission = {
    id: "",
    ref: "",
    name: "",
    job: "",
    startDate: "",
    endDate: "",
    dailyWorkingTime: 0,
    startDayTime: "",
    endDayTime: "",
    dailyBilling: false,
    status: "",
    professional: {
        nom: "",
        prenom: "",
    },
    structure: {
        societe: "",
        ville: null,
    },
    timeSheets: [
        {
            id: "",
            startDate: "",
            endDate: "",
            status: "",
        },
    ],
};
