import React, { useState, useEffect } from "react";
import { Modal, Box, Typography, Grid, Button, Stack } from "@mui/material";
import { useTranslation } from "react-i18next";
import { DateTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs, { Dayjs } from "dayjs";
interface EditTimesheetProps {
    isOpen: boolean;
    onClose: () => void;
    timesheet: any;
    handleSubmit: (timesheetId: string | null, updatedTimesheet: any) => void;
    handleDeleteTimesheet: (timesheetId: string) => void;
    timesheetId: string | null;
    missionStartDate: string;
    missionEndDate: string;
}

export const EditTimesheet: React.FC<EditTimesheetProps> = ({
    isOpen,
    onClose,
    timesheet,
    handleSubmit,
    handleDeleteTimesheet,
    timesheetId,
    missionStartDate,
    missionEndDate,
}) => {
    const { t } = useTranslation();

    const [localTimesheet, setLocalTimesheet] = useState({
        startDate: timesheet?.startDate
            ? dayjs(timesheet.startDate).utc()
            : dayjs(),
        endDate: timesheet?.endDate ? dayjs(timesheet.endDate).utc() : dayjs(),
        ...timesheet,
    });

    useEffect(() => {
        if (timesheet) {
            setLocalTimesheet({
                startDate: timesheet.startDate
                    ? dayjs(timesheet.startDate).utc()
                    : dayjs(),
                endDate: timesheet.endDate
                    ? dayjs(timesheet.endDate).utc()
                    : dayjs(),
                ...timesheet,
            });
        }
    }, [timesheet]);

    const handleTimeChange = (field: string, value: Dayjs | null) => {
        if (!value) return;

        const newValue = value.toISOString();
        if (localTimesheet[field] === newValue) return;

        setLocalTimesheet((prev: any) => ({
            ...prev,
            [field]: newValue,
        }));
    };

    useEffect(() => {}, [localTimesheet]);

    const handleSave = () => {
        const missionStart = dayjs(missionStartDate);
        const missionEnd = dayjs(missionEndDate).endOf("day");

        if (
            dayjs(localTimesheet.startDate).isBefore(missionStart) ||
            dayjs(localTimesheet.startDate).isAfter(missionEnd)
        ) {
            alert(t("error.start.date.out.of.range"));
            return;
        }

        if (
            dayjs(localTimesheet.endDate).isBefore(missionStart) ||
            dayjs(localTimesheet.endDate).isAfter(missionEnd)
        ) {
            alert(t("error.end.date.out.of.range"));
            return;
        }

        if (
            dayjs(localTimesheet.startDate).isAfter(
                dayjs(localTimesheet.endDate)
            )
        ) {
            alert(t("error.invalid.time.range"));
            return;
        }

        if (localTimesheet.id) {
            handleSubmit(localTimesheet.id, localTimesheet);
        }
        onClose();
    };

    const handleDelete = () => {
        if (timesheetId) {
            handleDeleteTimesheet(timesheetId);
            onClose();
        }
    };

    return (
        <Modal
            open={isOpen}
            onClose={onClose}
            aria-labelledby="edit-timesheet-modal"
            aria-describedby="edit-timesheet-description"
        >
            <Box
                sx={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    width: 400,
                    bgcolor: "background.paper",
                    boxShadow: 24,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                    p: 4,
                }}
            >
                <Typography variant="h6" mb={2}>
                    {t("mission.edit.timesheet")}
                </Typography>
                <Stack
                    padding="1rem"
                    mt={2}
                    gap="1rem"
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                >
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DateTimePicker
                            label={t("missions.hour.start")}
                            value={
                                localTimesheet.startDate
                                    ? dayjs(localTimesheet.startDate)
                                    : null
                            }
                            onChange={(newValue) => {
                                if (
                                    !newValue ||
                                    dayjs(localTimesheet.startDate).isSame(
                                        newValue
                                    )
                                )
                                    return;
                                handleTimeChange("startDate", newValue);
                            }}
                            ampm={false}
                            views={["hours", "minutes"]}
                            format="HH:mm"
                            minDateTime={dayjs(missionStartDate)}
                            maxDateTime={dayjs(missionEndDate).endOf("day")}
                        />

                        <DateTimePicker
                            label={t("missions.hour.end")}
                            value={
                                localTimesheet.endDate
                                    ? dayjs(localTimesheet.endDate)
                                    : null
                            }
                            onChange={(newValue) => {
                                if (
                                    !newValue ||
                                    dayjs(localTimesheet.endDate).isSame(
                                        newValue
                                    )
                                )
                                    return;
                                handleTimeChange("endDate", newValue);
                            }}
                            ampm={false}
                            views={["hours", "minutes"]}
                            format="HH:mm"
                            minDateTime={dayjs(missionStartDate)}
                            maxDateTime={dayjs(missionEndDate).endOf("day")}
                        />
                    </LocalizationProvider>
                    <Grid
                        item
                        xs={12}
                        display="flex"
                        justifyContent="space-around"
                        width="100%"
                        mt={2}
                    >
                        <Button
                            onClick={onClose}
                            color="secondary"
                            variant="contained"
                            sx={{ backgroundColor: "#E53935", color: "white" }}
                        >
                            {t("missions.cancel.button")}
                        </Button>
                        <Button
                            onClick={handleSave}
                            color="primary"
                            variant="contained"
                        >
                            {t("forgot.password.button.modal")}
                        </Button>
                    </Grid>
                    <Grid mt={2} width="100%">
                        <Button
                            onClick={handleDelete}
                            color="error"
                            sx={{
                                border: "1px #E53935 solid",
                                color: "#E53935",
                                width: "100%",
                            }}
                        >
                            {t("mission.delete.timesheet.button")}
                        </Button>
                    </Grid>
                </Stack>
            </Box>
        </Modal>
    );
};

export default EditTimesheet;
